import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { db, auth } from '../config/firebaseConfig';
import { AlertCircle, Check } from 'lucide-react';

const ProfilePage = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    dba: '',
    email: '',
    contactEmail: '',
    telephone: '',
    officeNumber: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    ein: '',
    primaryContactPerson: '',
    driversLicense: '',
    w9Form: '',
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!auth.currentUser) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserInfo(data);
          setFormData({
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            companyName: data.companyName || '',
            dba: data.dba || '',
            email: data.email || '',
            contactEmail: data.contactEmail || '',
            telephone: data.telephone || '',
            officeNumber: data.officeNumber || '',
            address: data.address || '',
            city: data.city || '',
            state: data.state || '',
            zip: data.zip || '',
            ein: data.ein || '',
            primaryContactPerson: data.primaryContactPerson || '',
            driversLicense: data.driversLicense || '',
            w9Form: data.w9Form || '',
          });
        }
      } catch (error) {
        setError('Failed to fetch user information');
      }
    };

    fetchUserInfo();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const updateData = { ...formData };
      delete updateData.driversLicense;
      delete updateData.w9Form;
      
      await updateDoc(userRef, updateData);
      setSuccess('Profile updated successfully');
      setIsEditing(false);
    } catch (error) {
      setError('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        passwordData.currentPassword
      );
      
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, passwordData.newPassword);
      
      setSuccess('Password updated successfully');
      setIsChangingPassword(false);
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    } catch (error) {
      setError(
        error.code === 'auth/wrong-password' 
          ? 'Current password is incorrect' 
          : 'Failed to update password'
      );
    } finally {
      setLoading(false);
    }
  };

  if (!userInfo) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="w-6 h-6 border-2 border-t-2 border-gray-200 rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="mt-[80px] min-h-screen bg-gray-50 py-24">
      <div className="max-w-4xl mx-auto px-4">
        <div className="bg-white rounded-lg shadow p-6">
          <h1 className="text-2xl font-bold mb-6">Account Profile</h1>

          {(error || success) && (
            <div className={`mb-4 p-4 rounded-lg flex items-center gap-2 ${
              error ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'
            }`}>
              {error ? <AlertCircle className="h-5 w-5" /> : <Check className="h-5 w-5" />}
              <span>{error || success}</span>
            </div>
          )}

          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Profile Information</h2>
              <button
                onClick={() => setIsEditing(!isEditing)}
                className="text-themeColorPrimary hover:text-themeColorPrimary2 font-medium"
              >
                {isEditing ? 'Cancel' : 'Edit'}
              </button>
            </div>

            {isEditing ? (
              <form onSubmit={handleProfileUpdate} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      DBA
                    </label>
                    <input
                      type="text"
                      name="dba"
                      value={formData.dba}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Contact Email
                    </label>
                    <input
                      type="email"
                      name="contactEmail"
                      value={formData.contactEmail}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Primary Contact Person
                    </label>
                    <input
                      type="text"
                      name="primaryContactPerson"
                      value={formData.primaryContactPerson}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Telephone
                    </label>
                    <input
                      type="tel"
                      name="telephone"
                      value={formData.telephone}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Office Number
                    </label>
                    <input
                      type="tel"
                      name="officeNumber"
                      value={formData.officeNumber}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      EIN
                    </label>
                    <input
                      type="text"
                      name="ein"
                      value={formData.ein}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      State
                    </label>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      ZIP Code
                    </label>
                    <input
                      type="text"
                      name="zip"
                      value={formData.zip}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                </div>

                <div className="pt-4">
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                  >
                    {loading ? 'Updating...' : 'Update Profile'}
                  </button>
                </div>
              </form>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="text-sm text-gray-500">Company Information</p>
                  <div className="mt-2 space-y-2">
                    <p><span className="font-medium">Company Name:</span> {formData.companyName}</p>
                    <p><span className="font-medium">DBA:</span> {formData.dba || 'N/A'}</p>
                    <p><span className="font-medium">EIN:</span> {formData.ein}</p>
                  </div>
                </div>

                <div>
                  <p className="text-sm text-gray-500">Contact Information</p>
                  <div className="mt-2 space-y-2">
                    <p><span className="font-medium">Name:</span> {formData.firstName} {formData.lastName}</p>
                    <p><span className="font-medium">Primary Contact:</span> {formData.primaryContactPerson}</p>
                    <p><span className="font-medium">Contact Email:</span> {formData.contactEmail}</p>
                  </div>
                </div>

                <div>
                  <p className="text-sm text-gray-500">Phone Numbers</p>
                  <div className="mt-2 space-y-2">
                    <p><span className="font-medium">Telephone:</span> {formData.telephone}</p>
                    <p><span className="font-medium">Office:</span> {formData.officeNumber || 'N/A'}</p>
                  </div>
                </div>

                <div>
                  <p className="text-sm text-gray-500">Address</p>
                  <div className="mt-2 space-y-2">
									<p><span className="font-medium">Street:</span> {formData.address}</p>
                    <p><span className="font-medium">City:</span> {formData.city}</p>
                    <p><span className="font-medium">State:</span> {formData.state}</p>
                    <p><span className="font-medium">ZIP:</span> {formData.zip}</p>
                  </div>
                </div>

                <div>
                  <p className="text-sm text-gray-500">Documents</p>
                  <div className="mt-2 space-y-2">
                    <div>
                      <span className="font-medium">Driver's License:</span>{' '}
                      {formData.driversLicense ? (
                        <a 
                          href={formData.driversLicense}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-themeColorPrimary hover:text-themeColorPrimary2"
                        >
                          View Document
                        </a>
                      ) : (
                        'Not uploaded'
                      )}
                    </div>
                    <div>
                      <span className="font-medium">W9 Form:</span>{' '}
                      {formData.w9Form ? (
                        <a 
                          href={formData.w9Form}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-themeColorPrimary hover:text-themeColorPrimary2"
                        >
                          View Document
                        </a>
                      ) : (
                        'Not uploaded'
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Password</h2>
              <button
                onClick={() => setIsChangingPassword(!isChangingPassword)}
                className="text-themeColorPrimary hover:text-themeColorPrimary2 font-medium"
              >
                {isChangingPassword ? 'Cancel' : 'Change Password'}
              </button>
            </div>

            {isChangingPassword && (
              <form onSubmit={handlePasswordUpdate} className="max-w-md">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Current Password
                    </label>
                    <input
                      type="password"
                      name="currentPassword"
                      value={passwordData.currentPassword}
                      onChange={handlePasswordChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      New Password
                    </label>
                    <input
                      type="password"
                      name="newPassword"
                      value={passwordData.newPassword}
                      onChange={handlePasswordChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Confirm New Password
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={passwordData.confirmPassword}
                      onChange={handlePasswordChange}
                      className="w-full p-2 border border-gray-300 rounded focus:ring-themeColorPrimary focus:border-themeColorPrimary"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                  >
                    {loading ? 'Updating...' : 'Update Password'}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;