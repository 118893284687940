import React, { useEffect, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import Routes from './Routes'
import Header from './components/Header'
import { useLocation } from 'wouter'
import { auth } from './config/firebaseConfig'
import Loading from './components/Loading'
import Footer from './components/Footer'
import { fetchUserInfo } from './utils/helpers'

const App = () => {
    const [location, navigate] = useLocation()
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [userInfo, setUserInfo] = useState(null)

    const [loggedIn, setLoggedIn] = useState(false)

    const VERSION = '2.8.0'

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setLoggedIn(true)
                setUser(user)
                const info = await fetchUserInfo(user.uid)
                setUserInfo(info)

                if (user.email !== 'admin@quidity.net') {
                    if (location === '/') {
                        navigate('/portal')
                    }
                    if (info.agreedToTerms === false) {
                        navigate('/terms')
                    }
                } else {
                    if (location === '/') {
                        navigate('/admin')
                    }
                }
            } else {
                setLoggedIn(false)
                setUser(null)
                setUserInfo(null)
                if (location === '/portal' || location === '/admin') {
                    navigate('/')
                }
            }
            setLoading(false) // Set loading to false after the user state is set
        })

        // Cleanup subscription on unmount
        return () => {
            unsubscribe()
        }
        // eslint-disable-next-line
    }, [navigate, location])

    useEffect(() => {
        if (user && userInfo) {
            if (user.email !== 'admin@quidity.net' && !userInfo.agreedToTerms) {
                navigate('/terms')
            } else if (location === '/terms' && userInfo.agreedToTerms) {
                navigate('/portal')
            }
        }
        // eslint-disable-next-line
    }, [user, userInfo])

    // Show loading component while checking authentication state
    if (loading) {
        return (
            <div className='h-screen'>
                <Loading />
            </div>
        )
    }

    return (
        <div className='flex flex-col app bg-themeColorPrimary3 min-h-screen'>
            <Header
                user={user}
                userInfo={userInfo}
                setLoggedIn={setLoggedIn}
                loggedIn={loggedIn}
            />
            <Routes user={user} userInfo={userInfo} setUserInfo={setUserInfo} />
            <Footer user={user} />
            <div className='mt-auto ml-auto text-base font-semibold p-4 pr-6'>
                v{VERSION}
            </div>
        </div>
    )
}

export default App
