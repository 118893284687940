import React, { useState } from 'react';
import { Upload } from 'lucide-react';
import axios from 'axios';
import { useLocation } from 'wouter';

const AdditionalFilesUpload = ({ dealId, userId, userEmail, onUploadComplete }) => {
  const [files, setFiles] = useState({
    bankStatementsAdditional: [],
    applicationsAdditional: [],
    taxReturnsAdditional: [],
    miscellaneousAdditional: []
  });
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [, navigate] = useLocation();

  const handleFileChange = (category, e) => {
    const newFiles = Array.from(e.target.files);
    setFiles(prev => ({
      ...prev,
      [category]: [...prev[category], ...newFiles]
    }));
  };

  const removeFile = (category, index) => {
    setFiles(prev => ({
      ...prev,
      [category]: prev[category].filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async () => {
    setUploading(true);
    setError('');
    setSuccess('');

    try {
      // Convert files to base64
      const processFiles = async (fileList) => {
        return Promise.all(
          fileList.map(async (file) => ({
            name: file.name,
            type: file.type,
            content: await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (e) => resolve(e.target.result.split(',')[1]);
              reader.onerror = reject;
              reader.readAsDataURL(file);
            })
          }))
        );
      };

      const processedFiles = {
        bankStatementsAdditional: await processFiles(files.bankStatementsAdditional),
        applicationsAdditional: await processFiles(files.applicationsAdditional),
        taxReturnsAdditional: await processFiles(files.taxReturnsAdditional),
        miscellaneousAdditional: await processFiles(files.miscellaneousAdditional)
      };
      // eslint-disable-next-line
      const response = await axios.post(
        'https://us-central1-quidity-app.cloudfunctions.net/uploadAdditionalFiles',
        {
          dealId,
          userId,
          userEmail,
          files: processedFiles
        }
      );

      setSuccess('Files uploaded successfully!');
      
      // Clear form
      setFiles({
        bankStatementsAdditional: [],
        applicationsAdditional: [],
        taxReturnsAdditional: [],
        miscellaneousAdditional: []
      });

      // Handle successful upload
      if (onUploadComplete) {
        onUploadComplete();
      }

      // Navigate back to deal page after short delay
      setTimeout(() => {
        navigate(`/deal/modify/${dealId}`);
      }, 1500);

    } catch (err) {
      setError('Error uploading files. Please try again.');
      console.error('Upload error:', err);
    } finally {
      setUploading(false);
    }
  };

  const hasFiles = Object.values(files).some(category => category.length > 0);

  const FileUploadSection = ({ title, category, accept }) => (
    <div className="mb-6">
      <label className="block font-medium text-gray-700 mb-2">{title}</label>
      <div className="flex items-center space-x-2">
        <label className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
          <Upload className="w-5 h-5 mr-2" />
          + Add Files
          <input
            type="file"
            className="hidden"
            multiple
            onChange={(e) => handleFileChange(category, e)}
            accept={accept}
          />
        </label>
        {files[category].length > 0 && (
          <span className="text-sm text-gray-600">
            {files[category].length} files selected
          </span>
        )}
      </div>
      {files[category].map((file, index) => (
        <div key={index} className="flex items-center justify-between text-sm mt-2">
          <span>{file.name}</span>
          <button
            onClick={() => removeFile(category, index)}
            className="text-red-600 hover:text-red-800"
          >
            Remove
          </button>
        </div>
      ))}
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <FileUploadSection 
        title="Bank Statements"
        category="bankStatementsAdditional"
        accept=".pdf,.doc,.docx,.xls,.xlsx"
      />

      <FileUploadSection 
        title="Applications"
        category="applicationsAdditional"
        accept=".pdf,.doc,.docx"
      />

      <FileUploadSection 
        title="Tax Returns"
        category="taxReturnsAdditional"
        accept=".pdf,.doc,.docx"
      />

      <FileUploadSection 
        title="Miscellaneous Documents"
        category="miscellaneousAdditional"
        accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
      />

      {error && (
        <div className="p-4 mb-4 text-red-700 bg-red-100 rounded-md">
          {error}
        </div>
      )}
      
      {success && (
        <div className="p-4 mb-4 text-green-700 bg-green-100 rounded-md">
          {success}
        </div>
      )}

      <button
        onClick={handleSubmit}
        disabled={!hasFiles || uploading}
        className={`w-full py-2 px-4 rounded-md text-white font-medium ${
          hasFiles && !uploading
            ? 'bg-themeColorPrimary hover:bg-themeColorPrimary2'
            : 'bg-gray-300 cursor-not-allowed'
        }`}
      >
        {uploading ? 'Uploading...' : 'Upload Selected Files'}
      </button>
    </div>
  );
};

export default AdditionalFilesUpload;