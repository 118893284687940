import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import { useLocation } from 'wouter'
import { v4 as uuidv4 } from 'uuid'
import {
    getLastThreeMonths,
    getLastTwoYears,
    industryCategories,
} from '../utils/helpers'

// Usage of the helper functions
const lastThreeMonths = getLastThreeMonths()
const lastTwoYears = getLastTwoYears()

const bank1 = `bankStatement${lastThreeMonths[0].monthName}${lastThreeMonths[0].year}`
const bank2 = `bankStatement${lastThreeMonths[1].monthName}${lastThreeMonths[1].year}`
const bank3 = `bankStatement${lastThreeMonths[2].monthName}${lastThreeMonths[2].year}`

const initialStyles = {
    businessName: {},
    dba: {},
    referrer: {},
    referrerEmail: {},
    useOfFunds: {},
    address: {},
    city: {},
    state: {},
    zip: {},
    tel: {},
    dateIncorporated: {},
    industry: {},
    businessDescription: {},
    ownerName: {},
    ownerTel: {},
    ownerEmail: {},
    ownerDOB: {},
    ownerSSN: {},
    pctOwnership: {},
    driversLicenseFront: {},
    bankStatement1: {},
    bankStatement2: {},
    bankStatement3: {},
    businessApplication: {},
    taxReturn1: {},
    taxReturn2: {},
    personalTaxReturn1: {},
    [bank1]: {},
    [bank2]: {},
    [bank3]: {},
}

const CreateDealPage = ({ user, userInfo }) => {
    const [formData, setFormData] = useState({
        // referrer: 'John Doe',
        // referrerEmail: 'test@example.com',
        // useOfFunds: 'Working capital and expansion',
        // businessName: 'Doe Enterprises LLC',
        // dba: 'Doe Trading',
        // address: '123 Main St',
        // city: 'New York',
        // state: 'NY',
        // zip: '10001',
        // tel: '5551234567',
        // dateIncorporated: '2010-05-15',
        // industry: 'Retail',
        // businessDescription: 'A retail company specializing in consumer goods.',
        // ownerName: 'John Doe',
        // ownerTel: '5559876543',
        // ownerEmail: 'owner@example.com',
        // ownerDOB: '1980-07-10',
        // ownerSSN: '123456789',
        // pctOwnership: '100',

        referrer: '',
        referrerEmail: '',
        useOfFunds: '',
        businessName: '',
        dba: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        tel: '',
        dateIncorporated: '',
        industry: '',
        businessDescription: '',
        ownerName: '',
        ownerTel: '',
        ownerEmail: '',
        ownerDOB: '',
        ownerSSN: '',
        pctOwnership: '',

        hasLargeLenderBalances: 'no',
        driversLicenseFront: '',
        bankStatement1: '',
        bankStatement2: '',
        bankStatement3: '',
        bankStatement4: '',
        businessApplication: '',
        plBusinessStatement: '',
        microSbaApplication: '',
        quidityLibriumSBA: '',
        taxReturn1: '',
        taxReturn2: '',
        personalTaxReturn1: '',
        [bank1]: '',
        [bank2]: '',
        [bank3]: '',
        bankStatementsAdditional: [],
        applicationsAdditional: [],
        taxReturnsAdditional: [],
        miscellaneousAdditional: [],
    })

    const invalidStyle = { outline: '2px solid red' } // Red outline for invalid fields
    const validStyle = { outline: '2px solid #078586' } // Green outline for valid fields

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitStatusMessage, setSubmitStatusMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    // eslint-disable-next-line
    const [location, navigate] = useLocation()
    const [fieldStyles, setFieldStyles] = useState(initialStyles)

    const [hasChanged, setHasChanged] = useState(false)

    const [isFormValid, setIsFormValid] = useState(false)

    // Validate formData and checkbox when they change
    useEffect(() => {
        console.log(initialStyles)
        setIsFormValid(areAllFieldsFilled())
        // eslint-disable-next-line
    }, [formData, isCheckboxChecked])

    useEffect(() => {
        if (hasChanged) {
            // Trigger validation for all fields after the first change
            Object.keys(formData).forEach((key) => {
                validateField(key, formData[key])
            })
        }
        // eslint-disable-next-line
    }, [formData, hasChanged])

    const handleChange = (e) => {
        const { name, value, files } = e.target

        if (!hasChanged) {
            setHasChanged(true)
        }

        if (files) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
            }))
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

        validateField(name, value || files?.[0]?.name) // Run validation on change
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setLoading(true)
            setSubmitStatusMessage('Submitting records...')

            // Utility function to check if a field is a file object
            const isFileObject = (file) => file instanceof File

            // Convert files to base64 dynamically
            // Convert files to base64 dynamically
            const convertFilesToBase64 = async (data) => {
                const convertedData = { ...data }

                // Convert standard fields
                const fieldsToConvert = [
                    'driversLicenseFront',
                    'driversLicenseBack',
                    'businessApplication',
                    'microLoanApplication',
                    'quidityLibriumSBA',
                    'quidityJumboLoan',
                    'bankStatementsAdditional',
                    'applicationsAdditional',
                    'taxReturnsAdditional',
                    'miscellaneousAdditional',
                    'businessTaxReturn2021',
                    'personalTaxReturn2021',
                    `businessTaxExtension${new Date().getFullYear() - 1}`,
                    `personalTaxExtension${new Date().getFullYear() - 1}`,
                    `plStatement${new Date().getFullYear() - 1}`,
                    `accountsReceivable${new Date().getFullYear() - 1}`,
                    `accountsPayables${new Date().getFullYear() - 1}`,
                ]

                for (const field of fieldsToConvert) {
                    if (isFileObject(data[field])) {
                        convertedData[field] = {
                            name: field,
                            content: await fileToBase64(data[field]),
                            type: data[field]?.type,
                        }
                    }
                }

                // Convert dynamic month-based bank statements
                for (const { monthName, year } of lastThreeMonths) {
                    const key = `bankStatement${monthName}${year}`
                    if (isFileObject(data[key])) {
                        convertedData[key] = {
                            name: key,
                            content: await fileToBase64(data[key]),
                            type: data[key]?.type,
                        }
                    }
                }

                // Convert dynamic year-based tax returns
                for (const year of lastTwoYears) {
                    const businessTaxKey = `taxReturn${year}`
                    const personalTaxKey = `personalTaxReturn${year}`
                    if (isFileObject(data[businessTaxKey])) {
                        convertedData[businessTaxKey] = {
                            name: businessTaxKey,
                            content: await fileToBase64(data[businessTaxKey]),
                            type: data[businessTaxKey]?.type,
                        }
                    }

                    if (isFileObject(data[personalTaxKey])) {
                        convertedData[personalTaxKey] = {
                            name: personalTaxKey,
                            content: await fileToBase64(data[personalTaxKey]),
                            type: data[personalTaxKey]?.type,
                        }
                    }
                }

                return convertedData
            }

            const additionalFiles = []

            // Process each additional file array by appending a unique name
            formData.bankStatementsAdditional?.forEach(async (file) => {
                additionalFiles.push({
                    name: `bankStatementsAdditional-${uuidv4()}`,
                    content: await fileToBase64(file),
                    type: file.type,
                    category: 'bankStatementsAdditional',
                })
            })

            formData.applicationsAdditional?.forEach(async (file) => {
                additionalFiles.push({
                    name: `applicationAdditional-${uuidv4()}`,
                    content: await fileToBase64(file),
                    type: file.type,
                    category: 'applicationsAdditional',
                })
            })

            formData.taxReturnsAdditional?.forEach(async (file) => {
                additionalFiles.push({
                    name: `taxReturnAdditional-${uuidv4()}`,
                    content: await fileToBase64(file),
                    type: file.type,
                    category: 'taxReturnsAdditional',
                })
            })

            formData.miscellaneousAdditional?.forEach(async (file) => {
                additionalFiles.push({
                    name: `miscellaneousAdditional-${uuidv4()}`,
                    content: await fileToBase64(file),
                    type: file.type,
                    category: 'miscellaneousAdditional',
                })
            })

            const docId = uuidv4()

            // Upload data to Firestore
            await setDoc(doc(db, 'deals', docId), {
                ...formData,
                userId: user.email,
                userEmail: user.email,
                dateSubmitted: serverTimestamp(),
                status: 'Processing',
                driversLicenseFront: '',
                bankStatement1: '',
                bankStatement2: '',
                bankStatement3: '',
                businessApplication: '',
                taxReturn1: '',
                taxReturn2: '',
                personalTaxReturn1: '',
                microSbaApplication: '',
                quidityLibriumSBA: '',
                plBusinessStatement: '',
                bankStatementsAdditional: [],
                applicationsAdditional: [],
                taxReturnsAdditional: [],
                miscellaneousAdditional: [],
                businessTaxReturn2021: '',
                personalTaxReturn2021: '',
                [`bankStatement${lastThreeMonths[0].monthName}${lastThreeMonths[0].year}`]:
                    '',
                [`bankStatement${lastThreeMonths[1].monthName}${lastThreeMonths[1].year}`]:
                    '',
                [`bankStatement${lastThreeMonths[2].monthName}${lastThreeMonths[2].year}`]:
                    '',
                [`taxReturn${lastTwoYears[0]}`]: '',
                [`taxReturn${lastTwoYears[1]}`]: '',
                [`personalTaxReturn${lastTwoYears[0]}`]: '',
                [`personalTaxReturn${lastTwoYears[1]}`]: '',
                [`plStatement${new Date().getFullYear() - 1}`]: '',
                [`businessTaxExtension${new Date().getFullYear() - 1}`]: '',
                [`personalTaxExtension${new Date().getFullYear() - 1}`]: '',
                [`accountsReceivable${new Date().getFullYear() - 1}`]: '',
                [`accountsPayables${new Date().getFullYear() - 1}`]: '',
            })

            const formDataObject = {
                ...(await convertFilesToBase64(formData)),
                docId,
            }

            // // Send data to external API
            const response = await axios.post(
                'https://us-central1-quidity-app.cloudfunctions.net/createDeal',
                {
                    formData: formDataObject,
                    userId: user.uid,
                    userEmail: user.email,
                    userInfo,
                    docId,
                    additionalFiles,
                }
            )

            setSubmitStatusMessage(response.data.message)
            setLoading(false)
            navigate('/portal')
        } catch (error) {
            console.error('Error submitting form:', error)
            setErrorMessage(error.message)
            setLoading(false)
        }
    }

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file || !(file instanceof Blob)) {
                // If the file is not provided or not a Blob, return 'N/A'
                resolve('N/A')
                return
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result.split(',')[1])
            reader.onerror = (error) => reject(error)
        })
    }

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailPattern.test(email)
    }

    const validateField = (name, value) => {
        let isValid = false

        if (typeof value === 'string') {
            value = value.trim()
        }
        switch (name) {
            // Add validation for file inputs
            case 'driversLicenseFront':
            case 'bankStatement1':
            case 'bankStatement2':
            case 'bankStatement3':
            case 'businessApplication':
            case bank1:
            case bank2:
            case bank3:
                isValid = !!value // Ensure the file or string is present
                break
            // Existing field checks
            case 'referrerEmail':
            case 'ownerEmail':
                isValid = validateEmail(value)
                break
            case 'zip':
                isValid = value?.length === 5
                break
            case 'tel':
            case 'ownerTel':
                isValid = value?.length === 10
                break
            case 'ownerSSN':
                isValid = value?.length === 9 && parseInt(value)
                break
            case 'businessName':
            case 'referrer':
            case 'useOfFunds':
            case 'address':
            case 'city':
            case 'state':
            case 'dateIncorporated':
            case 'industry':
            case 'businessDescription':
            case 'ownerName':
            case 'ownerDOB':
            case 'pctOwnership':
                isValid = typeof value === 'string' && value?.trim() !== ''
                break

            default:
                isValid = true
        }

        // Apply valid or invalid style
        setFieldStyles((prevStyles) => ({
            ...prevStyles,
            [name]: isValid ? validStyle : invalidStyle,
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target
        validateField(name, value)
    }

    const handleFocus = (e) => {
        const { name } = e.target
        let updatedStyles = { ...initialStyles }

        if (name === 'referrerEmail' || name === 'ownerEmail') {
            updatedStyles[name] = validateEmail(formData?.[name])
                ? validStyle
                : invalidStyle
        }

        setFieldStyles((prevStyles) => ({
            ...prevStyles,
        }))
    }

    const isFieldValid = (field, type = 'text') => {
        if (type === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            return emailRegex.test(field)
        } else if (type === 'tel') {
            return field.length === 10
        } else if (type === 'zip') {
            return field.length === 5
        } else if (type === 'ssn') {
            return field.length === 9
        } else if (typeof field === 'string') {
            return field.trim() !== ''
        } else if (field instanceof File) {
            return field.name !== '' // Checking if the file has a name
        } else {
            return field !== null && field !== undefined
        }
    }

    const areAllFieldsFilled = () => {
        const {
            referrer,
            referrerEmail,
            useOfFunds,
            businessName,
            address,
            city,
            state,
            zip,
            tel,
            dateIncorporated,
            industry,
            businessDescription,
            ownerName,
            ownerTel,
            ownerEmail,
            ownerDOB,
            ownerSSN,
            pctOwnership,
            driversLicenseFront,
            businessApplication,
        } = formData

        // Define the required fields and their validity
        return [
            isFieldValid(referrer),
            isFieldValid(referrerEmail, 'email'),
            isFieldValid(useOfFunds),
            isFieldValid(businessName),
            isFieldValid(address),
            isFieldValid(city),
            isFieldValid(state),
            isFieldValid(zip, 'zip'),
            isFieldValid(tel, 'tel'),
            isFieldValid(dateIncorporated),
            isFieldValid(industry),
            isFieldValid(businessDescription),
            isFieldValid(ownerName),
            isFieldValid(ownerTel, 'tel'),
            isFieldValid(ownerEmail, 'email'),
            isFieldValid(ownerDOB),
            isFieldValid(ownerSSN, 'ssn'),
            isFieldValid(pctOwnership),
            isFieldValid(driversLicenseFront),
            isFieldValid(businessApplication),
            isFieldValid(formData[bank1]),
            isFieldValid(formData[bank2]),
            isFieldValid(formData[bank3]),
            isCheckboxChecked,
        ].every(Boolean) // Returns true if all fields are valid
    }

    const DragAndDrop = ({ label, fileName, isMandatory = false }) => {
        const [isDragging, setIsDragging] = useState(false)

        const handleDragOver = (e) => {
            e.preventDefault()
            setIsDragging(true)
        }

        const handleDragLeave = () => {
            setIsDragging(false)
        }

        const handleDrop = (e) => {
            e.preventDefault()
            setIsDragging(false)
            const files = Array.from(e.dataTransfer.files)

            setFormData((prevState) => ({
                ...prevState,
                [fileName]: fileName.includes('Additional')
                    ? [...prevState[fileName], ...files] // Append to array for additional files
                    : files[0], // Single file assignment for non-additional fields
            }))

            // Validate each file
            files.forEach((file) => validateField(fileName, file.name))
        }

        const handleFileChange = (e) => {
            const files = Array.from(e.target.files)

            setFormData((prevState) => ({
                ...prevState,
                [fileName]: fileName.includes('Additional')
                    ? [...prevState[fileName], ...files] // Append for additional files
                    : files[0], // Assign single file
            }))

            // Validate each file
            files.forEach((file) => validateField(fileName, file.name))
        }

        // Determine styles based on validation
        const getBorderStyle = () => {
            if (fieldStyles[fileName]?.outline === '2px solid red') {
                return 'border-red-500 bg-red-50'
            } else if (fieldStyles[fileName]?.outline === '2px solid #078586') {
                return 'border-green-500 bg-green-50'
            } else if (isDragging) {
                return 'border-blue-400 bg-blue-50'
            } else {
                return 'border-gray-300'
            }
        }

        return (
            <div className='flex items-center gap-4 mb-4'>
                {/* + Add Button */}
                <div>
                    <label
                        htmlFor={fileName}
                        className='w-[120px] flex justify-center h-full cursor-pointer text-center p-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 transition'
                    >
                        + Add {fileName.includes('Additional') ? 'Files' : ''}
                    </label>
                    <input
                        id={fileName}
                        type='file'
                        onChange={handleFileChange}
                        multiple={fileName.includes('Additional')} // Enable multiple file selection for additional fields
                        className='hidden'
                    />
                </div>
                {/* Drag and Drop Area */}
                <div
                    className={`flex-1 border border-dashed ${getBorderStyle()} p-4 rounded-md transition-colors duration-200 cursor-pointer`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={(e) => handleDrop(e)}
                >
                    <p className='text-sm text-gray-500'>
                        Drag & drop file here{' '}
                        {isMandatory && <b className='text-red-600'>*</b>}
                    </p>
                    {/* Display files */}
                    {fileName.includes('Additional')
                        ? // Render list for multiple files in additional fields
                          formData[fileName]?.map((file, index) => (
                              <p
                                  key={index}
                                  className='mt-2 text-sm text-gray-700'
                              >
                                  Selected: {file.name}
                              </p>
                          ))
                        : // Render single file for non-additional fields
                          formData[fileName] && (
                              <p className='mt-2 text-sm text-gray-700'>
                                  Selected: {formData[fileName].name}
                              </p>
                          )}
                </div>
            </div>
        )
    }

    return (
        user && (
            <div
                className='bg-themeColorPrimary3 min-h-screen flex flex-col justify-center items-center'
                style={{ animation: 'bottom-top 500ms ease' }}
            >
                <div className='mt-[200px] md:mt-[150px] lg:mt-[150px] bg-white shadow-md rounded-xl px-8 pt-6 pb-8 w-[95%] md:w-[800px] lg:w-[800px] mb-[50px]'>
                    <h1 className='block w-full text-center text-grey-darker text-[28px] mb-6 font-bold text-themeColorPrimary2'>
                        Create Deal
                    </h1>
                    <form onSubmit={handleSubmit}>
                        <p className='text-lg font-medium mb-2 text-center'>
                            Who should we contact regarding this merchant?
                        </p>
                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='address'
                                >
                                    Referrer *
                                </label>
                                <input
                                    style={fieldStyles.referrer}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    id='referrer'
                                    name='referrer'
                                    value={formData.referrer}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='referrerEmail'
                                >
                                    Referrer Email *
                                </label>
                                <input
                                    style={fieldStyles.referrerEmail}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    id='referrerEmail'
                                    name='referrerEmail'
                                    value={formData.referrerEmail}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='businessName'
                                >
                                    Business Name *
                                </label>
                                <input
                                    style={fieldStyles.businessName}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    id='businessName'
                                    name='businessName'
                                    value={formData.businessName}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                        </div>

                        <div className='mb-4 w-full'>
                            <label
                                className='block text-grey-darker text-sm font-bold mb-2'
                                htmlFor='address'
                            >
                                Company Address *
                            </label>
                            <input
                                style={fieldStyles.address}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                type='text'
                                id='address'
                                name='address'
                                value={formData.address}
                                onChange={handleChange}
                                className='themed-input'
                                required
                            />
                        </div>

                        <div className='grid grid-cols-3 gap-0 md:gap-4 lg:gap-4 mb-4'>
                            <input
                                style={fieldStyles.city}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                type='text'
                                placeholder='City *'
                                id='city'
                                name='city'
                                value={formData.city}
                                onChange={handleChange}
                                className='input input-bordered w-full col-span-1 themed-input'
                                required
                            />
                            <select
                                style={fieldStyles.state}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                id='state'
                                name='state'
                                value={formData.state}
                                onChange={handleChange}
                                className='input input-bordered w-full col-span-1 themed-input cursor-pointer'
                                required
                            >
                                <option value=''>State *</option>
                                <option value='AL'>AL</option>
                                <option value='AK'>AK</option>
                                <option value='AZ'>AZ</option>
                                <option value='AR'>AR</option>
                                <option value='CA'>CA</option>
                                <option value='CO'>CO</option>
                                <option value='CT'>CT</option>
                                <option value='DE'>DE</option>
                                <option value='FL'>FL</option>
                                <option value='GA'>GA</option>
                                <option value='HI'>HI</option>
                                <option value='ID'>ID</option>
                                <option value='IL'>IL</option>
                                <option value='IN'>IN</option>
                                <option value='IA'>IA</option>
                                <option value='KS'>KS</option>
                                <option value='KY'>KY</option>
                                <option value='LA'>LA</option>
                                <option value='ME'>ME</option>
                                <option value='MD'>MD</option>
                                <option value='MA'>MA</option>
                                <option value='MI'>MI</option>
                                <option value='MN'>MN</option>
                                <option value='MS'>MS</option>
                                <option value='MO'>MO</option>
                                <option value='MT'>MT</option>
                                <option value='NE'>NE</option>
                                <option value='NV'>NV</option>
                                <option value='NH'>NH</option>
                                <option value='NJ'>NJ</option>
                                <option value='NM'>NM</option>
                                <option value='NY'>NY</option>
                                <option value='NC'>NC</option>
                                <option value='ND'>ND</option>
                                <option value='OH'>OH</option>
                                <option value='OK'>OK</option>
                                <option value='OR'>OR</option>
                                <option value='PA'>PA</option>
                                <option value='RI'>RI</option>
                                <option value='SC'>SC</option>
                                <option value='SD'>SD</option>
                                <option value='TN'>TN</option>
                                <option value='TX'>TX</option>
                                <option value='UT'>UT</option>
                                <option value='VT'>VT</option>
                                <option value='VA'>VA</option>
                                <option value='WA'>WA</option>
                                <option value='WV'>WV</option>
                                <option value='WI'>WI</option>
                                <option value='WY'>WY</option>
                            </select>
                            <input
                                style={fieldStyles.zip}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                maxLength={5}
                                type='text'
                                placeholder='Zip Code *'
                                id='zip'
                                name='zip'
                                value={formData.zip}
                                onChange={handleChange}
                                className='input input-bordered w-full col-span-1 themed-input'
                                required
                            />
                        </div>

                        <div className='flex items-center flex-col md:flex-row lg:flex-row w-full gap-0 md:gap-4 lg:gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='tel'
                                >
                                    Telephone *
                                </label>
                                <input
                                    style={fieldStyles.tel}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    maxLength={10}
                                    id='tel'
                                    name='tel'
                                    value={formData.tel}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='dateIncorporated'
                                >
                                    Date Incorporated *
                                </label>
                                <input
                                    style={fieldStyles.dateIncorporated}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='date'
                                    id='dateIncorporated'
                                    name='dateIncorporated'
                                    value={formData.dateIncorporated}
                                    onChange={handleChange}
                                    className='themed-input'
                                    max='9999-12-31'
                                    required
                                />
                            </div>
                        </div>

                        <div className='mb-4 w-full'>
                            <label
                                className='block text-grey-darker text-sm font-bold mb-2'
                                htmlFor='businessDescription'
                            >
                                Business Description *
                            </label>
                            <textarea
                                maxLength={250}
                                style={fieldStyles.businessDescription}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                id='businessDescription'
                                name='businessDescription'
                                value={formData.businessDescription}
                                onChange={handleChange}
                                className='themed-input'
                                required
                            />
                        </div>

                        <div className='mb-4 w-full'>
                            <label
                                className='block text-grey-darker text-sm font-bold mb-2'
                                htmlFor='useOfFunds'
                            >
                                Use of Funds *
                            </label>
                            <input
                                style={fieldStyles.useOfFunds}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                type='text'
                                id='useOfFunds'
                                name='useOfFunds'
                                value={formData.useOfFunds}
                                onChange={handleChange}
                                className='themed-input'
                                required
                            />
                        </div>

                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='industry'
                                >
                                    Industry *
                                </label>
                                <select
                                    style={fieldStyles.industry}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    id='industry'
                                    name='industry'
                                    value={formData.industry}
                                    onChange={handleChange}
                                    className='themed-input cursor-pointer'
                                    required
                                >
                                    <option value=''>Select Industry</option>
                                    {Object.keys(industryCategories).map(
                                        (ind) => (
                                            <option key={ind} value={ind}>
                                                {ind}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerTel'
                                >
                                    Business Owner Telephone *
                                </label>
                                <input
                                    style={fieldStyles.ownerTel}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    maxLength={10}
                                    id='ownerTel'
                                    name='ownerTel'
                                    value={formData.ownerTel}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerName'
                                >
                                    Business Owner Name *
                                </label>
                                <input
                                    style={fieldStyles.ownerName}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    id='ownerName'
                                    name='ownerName'
                                    value={formData.ownerName}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerEmail'
                                >
                                    Business Owner Email *
                                </label>
                                <input
                                    style={fieldStyles.ownerEmail}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='email'
                                    id='ownerEmail'
                                    name='ownerEmail'
                                    value={formData.ownerEmail}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row lg:flex-row w-full gap-4'>
                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerDOB'
                                >
                                    Business Owner DOB *
                                </label>
                                <input
                                    style={fieldStyles.ownerDOB}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='date'
                                    id='ownerDOB'
                                    name='ownerDOB'
                                    value={formData.ownerDOB}
                                    onChange={handleChange}
                                    className='themed-input'
                                    max='9999-12-31'
                                    required
                                />
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='ownerSSN'
                                >
                                    Business Owner SSN *
                                </label>
                                <input
                                    style={fieldStyles.ownerSSN}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type='text'
                                    maxLength={9}
                                    id='ownerSSN'
                                    name='ownerSSN'
                                    value={formData.ownerSSN}
                                    onChange={handleChange}
                                    className='themed-input'
                                    required
                                />
                            </div>

                            <div className='mb-4 w-full'>
                                <label
                                    className='block text-grey-darker text-sm font-bold mb-2'
                                    htmlFor='pctOwnership'
                                >
                                    Percentage Ownership *
                                </label>
                                <select
                                    style={fieldStyles.pctOwnership}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    id='pctOwnership'
                                    name='pctOwnership'
                                    value={formData.pctOwnership}
                                    onChange={handleChange}
                                    className='themed-input cursor-pointer'
                                    required
                                >
                                    <option value=''>Select Percentage</option>
                                    <option value='10'>10%</option>
                                    <option value='20'>20%</option>
                                    <option value='30'>30%</option>
                                    <option value='40'>40%</option>
                                    <option value='50'>50%</option>
                                    <option value='60'>60%</option>
                                    <option value='70'>70%</option>
                                    <option value='80'>80%</option>
                                    <option value='90'>90%</option>
                                    <option value='100'>100%</option>
                                </select>
                            </div>
                        </div>

                        <div className='mt-4 mb-4 w-full'>
                            <label className='block text-grey-darker text-sm font-bold mb-2'>
                                Does applicant have existing lender balances
                                over $50,000?
                            </label>
                            <div className='flex items-center space-x-4'>
                                <label className='flex items-center space-x-2 cursor-pointer'>
                                    <input
                                        type='radio'
                                        name='hasLargeLenderBalances'
                                        value='yes'
                                        checked={
                                            formData.hasLargeLenderBalances ===
                                            'yes'
                                        }
                                        onChange={handleChange}
                                        className='h-[50px] w-[20px] cursor-pointer'
                                    />
                                    <span className='text-[14px] font-semibold'>
                                        Yes
                                    </span>
                                </label>
                                <label className='flex items-center space-x-2 cursor-pointer'>
                                    <input
                                        type='radio'
                                        name='hasLargeLenderBalances'
                                        value='no'
                                        checked={
                                            formData.hasLargeLenderBalances ===
                                            'no'
                                        }
                                        onChange={handleChange}
                                        className='h-[50px] w-[20px] cursor-pointer'
                                    />
                                    <span className='text-[14px] font-semibold'>
                                        No
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* File Upload Fields */}
                        <section className='mb-8'>
                            <h3 className='text-lg underline font-bold mb-4'>
                                Upload Required Documents:
                            </h3>

                            {/* Bank Statements */}
                            <h4 className='text-md font-bold mb-2 underline'>
                                Bank Statements
                            </h4>
                            {lastThreeMonths.map((monthData, index) => (
                                <div
                                    key={`bankStatement-${index}`}
                                    className='mb-4'
                                >
                                    <p className='text-sm font-bold mb-1'>{`${monthData.monthName} ${monthData.year} Bank Statement`}</p>
                                    <DragAndDrop
                                        label={`${monthData.monthName} ${monthData.year} Bank Statement`}
                                        fileName={`bankStatement${monthData.monthName}${monthData.year}`}
                                        isMandatory={true}
                                    />
                                </div>
                            ))}
                            {/* Display Additional Bank Statements */}
                            {formData.bankStatementsAdditional.map(
                                (file, index) => (
                                    <div key={index} className='mb-4'>
                                        <p className='text-sm text-gray-700'>
                                            {file.name}
                                        </p>
                                    </div>
                                )
                            )}
                            <DragAndDrop
                                label={`Bank Statements Additional`}
                                fileName={`bankStatementsAdditional`}
                            />

                            {/* Applications */}
                            <h4 className='text-md font-bold mt-6 mb-2 underline'>
                                Applications
                            </h4>
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Business Application
                                </p>
                                <DragAndDrop
                                    label='Business Application'
                                    fileName='businessApplication'
                                    isMandatory={true}
                                />
                            </div>
                            {/* <div className='mb-4'>
    <p className="text-sm font-bold mb-1">Micro Loan Application</p>
    <DragAndDrop
      label="Micro Loan Application"
      fileName="microSbaApplication"
    />
  </div> */}
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Quidity SBA Loan
                                </p>
                                <DragAndDrop
                                    label='Quidity Librium SBA Application'
                                    fileName='quidityLibriumSBA'
                                />
                            </div>
                            {/* <div className='mb-4'>
    <p className="text-sm font-bold mb-1">Quidity Jumbo Loan Application</p>
    <DragAndDrop
      label="Quidity Jumbo Loan Application"
      fileName="quidityJumboLoan"
    />
  </div> */}
                            {/* Display Additional Applications */}
                            {formData.applicationsAdditional.map(
                                (file, index) => (
                                    <div key={index} className='mb-4'>
                                        <p className='text-sm text-gray-700'>
                                            {file.name}
                                        </p>
                                    </div>
                                )
                            )}
                            <DragAndDrop
                                label={`Applications Additional`}
                                fileName={`applicationsAdditional`}
                            />

                            {/* Tax Documents */}
                            <h3 className='text-lg underline font-bold mb-4'>
                                Tax Returns:
                            </h3>

                            {/* Business Tax Returns */}
                            <h4 className='text-md font-bold mb-2 underline'>
                                Business Tax Returns
                            </h4>
                            {lastTwoYears.map((year, index) => (
                                <div key={`taxReturn-${year}`} className='mb-4'>
                                    <p className='text-sm font-bold mb-1'>{`Business Tax Return ${year}`}</p>
                                    <DragAndDrop
                                        label={`Business Tax Return ${year}`}
                                        fileName={`taxReturn${year}`}
                                    />
                                </div>
                            ))}
                            <div key='businessTaxReturn2021' className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Business Tax Return 2021
                                </p>
                                <DragAndDrop
                                    label='Business Tax Return 2021'
                                    fileName='businessTaxReturn2021'
                                />
                            </div>
                            <DragAndDrop
                                label='Tax Returns Additional'
                                fileName='taxReturnsAdditional'
                            />

                            {/* Personal Tax Returns */}
                            <h4 className='text-md font-bold mt-6 mb-2 underline'>
                                Personal Tax Returns
                            </h4>
                            {lastTwoYears.map((year, index) => (
                                <div
                                    key={`personalTaxReturn-${year}`}
                                    className='mb-4'
                                >
                                    <p className='text-sm font-bold mb-1'>{`Personal Tax Return ${year}`}</p>
                                    <DragAndDrop
                                        label={`Personal Tax Return ${year}`}
                                        fileName={`personalTaxReturn${year}`}
                                    />
                                </div>
                            ))}
                            <div key='personalTaxReturn2021' className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Personal Tax Return 2021
                                </p>
                                <DragAndDrop
                                    label='Personal Tax Return 2021'
                                    fileName='personalTaxReturn2021'
                                />
                            </div>
                            <DragAndDrop
                                label='Tax Returns Additional'
                                fileName='taxReturnsAdditional'
                            />

                            {/* Tax Extensions */}
                            <h4 className='text-md font-bold mt-6 mb-2 underline'>
                                Tax Extensions
                            </h4>
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Business Tax Extension{' '}
                                    {new Date().getFullYear() - 1}
                                </p>
                                <DragAndDrop
                                    label={`Business Tax Extension ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`businessTaxExtension${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Personal Tax Extension{' '}
                                    {new Date().getFullYear() - 1}
                                </p>
                                <DragAndDrop
                                    label={`Personal Tax Extension ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`personalTaxExtension${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>

                            {/* Display Additional Tax Returns */}
                            {formData.taxReturnsAdditional.map(
                                (file, index) => (
                                    <div key={index} className='mb-4'>
                                        <p className='text-sm text-gray-700'>
                                            {file.name}
                                        </p>
                                    </div>
                                )
                            )}

                            {/* Miscellaneous Documents */}
                            <h4 className='text-md font-bold mt-6 mb-2 underline'>
                                Miscellaneous Documents
                            </h4>
                            <div className='mb-4'>
                                <p className='text-sm font-bold mb-1'>
                                    Driver's License Front
                                </p>
                                <DragAndDrop
                                    label="Driver's License Front"
                                    fileName='driversLicenseFront'
                                    isMandatory={true}
                                />
                            </div>
                            <div
                                key={`plStatement-${
                                    new Date().getFullYear() - 1
                                }`}
                                className='mb-4'
                            >
                                <p className='text-sm font-bold mb-1'>{`P & L Statement ${
                                    new Date().getFullYear() - 1
                                }`}</p>
                                <DragAndDrop
                                    label={`P & L Statement ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`plStatement${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>
                            <div
                                key={`accountsReceivable-${
                                    new Date().getFullYear() - 1
                                }`}
                                className='mb-4'
                            >
                                <p className='text-sm font-bold mb-1'>{`Accounts Receivable ${
                                    new Date().getFullYear() - 1
                                }`}</p>
                                <DragAndDrop
                                    label={`Accounts Receivable ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`accountsReceivable${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>
                            <div
                                key={`accountsPayables-${
                                    new Date().getFullYear() - 1
                                }`}
                                className='mb-4'
                            >
                                <p className='text-sm font-bold mb-1'>{`Accounts Payables ${
                                    new Date().getFullYear() - 1
                                }`}</p>
                                <DragAndDrop
                                    label={`Accounts Payables ${
                                        new Date().getFullYear() - 1
                                    }`}
                                    fileName={`accountsPayables${
                                        new Date().getFullYear() - 1
                                    }`}
                                />
                            </div>
                            {/* Display Additional Miscellaneous Documents */}
                            {formData.miscellaneousAdditional.map(
                                (file, index) => (
                                    <div key={index} className='mb-4'>
                                        <p className='text-sm text-gray-700'>
                                            {file.name}
                                        </p>
                                    </div>
                                )
                            )}
                            <DragAndDrop
                                label={`Miscellaneous Additional`}
                                fileName={`miscellaneousAdditional`}
                            />
                        </section>

                        <div className='mt-2 mb-2'>
                            <input
                                id='checkbox-create-deal'
                                type='checkbox'
                                className='mr-2 cursor-pointer scale-[1.2]'
                                value={isCheckboxChecked}
                                onChange={() =>
                                    setIsCheckboxChecked(!isCheckboxChecked)
                                }
                            />
                            <label
                                className='cursor-pointer text-sm'
                                htmlFor='checkbox-create-deal'
                            >
                                The above aforementioned (“Referrer”) refers
                                clients for Quidity, LLC's loan programs and
                                certifies authority to submit applications. The
                                Referrer confirms all information and referrals
                                are accurate and truthful. Referrer authorizes
                                Quidity, LLC, and affiliates to (a) Obtain
                                reports about the merchant, (b) Access the
                                merchant's financial statements, (c) Share
                                information with authorized parties, (d) Receive
                                information from banks/creditors, (e) Contact
                                the merchant via text, call, or email. The
                                referrer is responsible for information
                                accuracy. This authorization lasts through the
                                loan process and term unless revoked. Quidity,
                                LLC will use the information solely for loan
                                evaluation and servicing, complying with
                                applicable laws.
                            </label>
                        </div>
                        {/* {
             &&
            <p className='mt-4 font-bold text-red-500'>
              Please confirm above information.
            </p>
          } */}
                        {(!isFormValid || !isCheckboxChecked) && (
                            <p className='mt-4 font-bold text-red-500'>
                                Please complete all required fields
                            </p>
                        )}
                        <div className='flex items-center justify-between'>
                            <button
                                type='submit'
                                className='mt-4 bg-themeColorPrimary hover:bg-themeColorPrimary2 text-white font-bold py-2 px-4 rounded disabled:opacity-[50%] disabled:hover:bg- disabled:bg-gray-600'
                                disabled={!isFormValid || loading}
                            >
                                {!loading ? (
                                    'Create Deal'
                                ) : (
                                    <div className='flex items-center'>
                                        <div className='button-loader mr-2'></div>
                                        Please wait
                                    </div>
                                )}
                            </button>
                        </div>
                        <p className='mt-4 font-bold text-themeColorPrimary2'>
                            {loading && submitStatusMessage}
                        </p>
                    </form>
                    <p className='mt-4 font-bold text-red-500'>
                        {errorMessage}
                    </p>
                </div>
            </div>
        )
    )
}

export default CreateDealPage
