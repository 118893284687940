import React, { useState, useEffect } from 'react';
import { Upload, AlertCircle } from 'lucide-react';
import axios from 'axios';
import { useLocation } from 'wouter';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';

const UploadBanner = ({ selectedMessage, onClose }) => {
  if (!selectedMessage) return null;

  return (
    <div className="bg-[#CD2B2B] text-white rounded-t-lg">
      <div className="w-full px-6 py-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <span className="text-base font-normal">
              {selectedMessage.message}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdditionalFilesUpload = ({ dealId, userId, userEmail, onUploadComplete, currentDeal, selectedMessage }) => {
  const [businessName, setBusinessName] = useState(currentDeal?.businessName || '');
  const [files, setFiles] = useState({
    bankStatementsAdditional: [],
    applicationsAdditional: [],
    taxReturnsAdditional: [],
    miscellaneousAdditional: []
  });
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showBanner, setShowBanner] = useState(!!selectedMessage);
  const [, navigate] = useLocation();

  useEffect(() => {
    setShowBanner(!!selectedMessage);
  }, [selectedMessage]);

  useEffect(() => {
    if (currentDeal?.businessName) {
      setBusinessName(currentDeal.businessName);
    }
  }, [currentDeal]);

  useEffect(() => {
    const fetchBusinessName = async () => {
      if (!businessName) {
        try {
          const dealRef = doc(db, 'deals', dealId);
          const dealSnap = await getDoc(dealRef);
          if (dealSnap.exists()) {
            const dealData = dealSnap.data();
            setBusinessName(dealData.businessName);
          }
        } catch (error) {
          console.error('Error fetching business name:', error);
        }
      }
    };

    fetchBusinessName();
  }, [dealId, businessName]);

  const handleFileChange = (category, e) => {
    const newFiles = Array.from(e.target.files);
    setFiles(prev => ({
      ...prev,
      [category]: [...prev[category], ...newFiles]
    }));
  };

  const removeFile = (category, index) => {
    setFiles(prev => ({
      ...prev,
      [category]: prev[category].filter((_, i) => i !== index)
    }));
  };

  const handleCloseBanner = () => {
    setShowBanner(false);
    if (onUploadComplete) {
      onUploadComplete();
    }
  };

  const handleSubmit = async () => {
    setUploading(true);
    setError('');
    setSuccess('');

    try {
      const processFiles = async (fileList) => {
        return Promise.all(
          fileList.map(async (file) => ({
            name: file.name,
            type: file.type,
            content: await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (e) => resolve(e.target.result.split(',')[1]);
              reader.onerror = reject;
              reader.readAsDataURL(file);
            })
          }))
        );
      };

      const processedFiles = {
        bankStatementsAdditional: await processFiles(files.bankStatementsAdditional),
        applicationsAdditional: await processFiles(files.applicationsAdditional),
        taxReturnsAdditional: await processFiles(files.taxReturnsAdditional),
        miscellaneousAdditional: await processFiles(files.miscellaneousAdditional)
      };

      await axios.post(
        'https://us-central1-quidity-app.cloudfunctions.net/uploadAdditionalFiles',
        {
          dealId,
          userId,
          userEmail,
          files: processedFiles
        }
      );

      setSuccess('Files uploaded successfully!');
      
      setFiles({
        bankStatementsAdditional: [],
        applicationsAdditional: [],
        taxReturnsAdditional: [],
        miscellaneousAdditional: []
      });

      if (onUploadComplete) {
        onUploadComplete();
      }

      setTimeout(() => {
        navigate(`/deal/modify/${dealId}`);
        window.location.reload();
      }, 1500);

    } catch (err) {
      setError('Error uploading files. Please try again.');
      console.error('Upload error:', err);
    } finally {
      setUploading(false);
    }
  };

  const hasFiles = Object.values(files).some(category => category.length > 0);

  const FileUploadSection = ({ title, category, accept }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [showAcceptedTypes, setShowAcceptedTypes] = useState(false);

    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = () => {
      setIsDragging(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragging(false);
      const droppedFiles = Array.from(e.dataTransfer.files);
      if (droppedFiles.length > 0) {
        handleFileChange(category, { target: { files: droppedFiles } });
      }
    };

    return (
      <div className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <label className="block font-medium text-gray-700">{title}</label>
          <div 
            className="text-xs text-gray-500 cursor-help"
            onMouseEnter={() => setShowAcceptedTypes(true)}
            onMouseLeave={() => setShowAcceptedTypes(false)}
          >
            {showAcceptedTypes && (
              <div className="absolute bg-white shadow-lg rounded-md p-2 z-10">
                Accepted types: {accept}
              </div>
            )}
            Accepted file types
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <div
            className={`hidden md:flex lg:flex items-center border border-dashed rounded-md transition-colors duration-200 h-10 ${
              isDragging 
                ? 'border-themeColorPrimary bg-blue-50' 
                : 'border-gray-300 hover:border-gray-400'
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="flex items-center px-3 flex-1">
              <Upload className="w-4 h-4 text-gray-400 mr-2" />
              <span className="text-sm text-gray-600">
                Drop files here or
              </span>
            </div>
            <label className="cursor-pointer px-4 h-full flex items-center border-l border-gray-200 bg-gray-50 text-sm font-medium text-gray-700 hover:bg-gray-100">
              Browse
              <input
                type="file"
                className="hidden"
                multiple
                onChange={(e) => handleFileChange(category, e)}
                accept={accept}
              />
            </label>
          </div>

          <div className="md:hidden lg:hidden">
            <label className="cursor-pointer inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
              <Upload className="w-4 h-4 mr-2" />
              Select Files
              <input
                type="file"
                className="hidden"
                multiple
                onChange={(e) => handleFileChange(category, e)}
                accept={accept}
              />
            </label>
          </div>

          {files[category].length > 0 && (
            <div className="text-sm text-gray-600 mt-1">
              {files[category].length} file(s) selected
            </div>
          )}
          
          <div className="space-y-1">
            {files[category].map((file, index) => (
              <div key={index} className="flex items-center justify-between text-sm bg-gray-50 p-2 rounded-md">
                <span className="truncate max-w-xs">{file.name}</span>
                <button
                  onClick={() => removeFile(category, index)}
                  className="text-red-600 hover:text-red-800 font-medium ml-2 text-xs"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      {showBanner && (
        <UploadBanner 
          selectedMessage={selectedMessage}
          onClose={handleCloseBanner}
        />
      )}
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-6">
          Business Name: <span className="font-semibold">{businessName || 'Loading...'}</span>
        </h3>
        
        <div className="space-y-6">
          <FileUploadSection 
            title="Bank Statements"
            category="bankStatementsAdditional"
            accept=".pdf,.doc,.docx,.xls,.xlsx"
          />

          <FileUploadSection 
            title="Applications"
            category="applicationsAdditional"
            accept=".pdf,.doc,.docx"
          />

          <FileUploadSection 
            title="Tax Returns"
            category="taxReturnsAdditional"
            accept=".pdf,.doc,.docx"
          />

          <FileUploadSection 
            title="Miscellaneous Documents"
            category="miscellaneousAdditional"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
          />
        </div>

        {error && (
          <div className="p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-md mt-6">
            {error}
          </div>
        )}
        
        {success && (
          <div className="p-3 mb-4 text-sm text-green-700 bg-green-100 rounded-md mt-6">
            {success}
          </div>
        )}

        <button
          onClick={handleSubmit}
          disabled={!hasFiles || uploading}
          className={`w-full py-2 px-4 rounded-md text-white font-medium mt-6 ${
            hasFiles && !uploading
              ? 'bg-themeColorPrimary hover:bg-themeColorPrimary2'
              : 'bg-gray-300 cursor-not-allowed'
          }`}
        >
          {uploading ? 'Uploading...' : 'Upload Selected Files'}
        </button>
      </div>
    </div>
  );
};

export default AdditionalFilesUpload;